import { TG_SUPPORT_EMAIL, TG_SUPPORT_USERNAME } from './admins';

export const CONTACTS_LIST: {
  title: langData;
  url?: string;
  text: string;
}[] = [
  {
    title: 'contactsTG',
    url: `https://t.me/${TG_SUPPORT_USERNAME}`,
    text: `@${TG_SUPPORT_USERNAME}`,
  },
  {
    title: 'contactsEmail',
    url: `mailto:${TG_SUPPORT_EMAIL}`,
    text: TG_SUPPORT_EMAIL,
  },
];
