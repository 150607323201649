import React from 'react';
import { langString } from '../../lang';
import { CurrencyName } from '../../config/currency';

export abstract class BasePage<T, PP> extends React.Component<
  { lang: langType; onChangeLang?: (lang: langType) => void } & PP,
  T
> {
  abstract state: Readonly<T>;
  get currency(): CurrencyName {
    if (this.props.lang === 'ru') return 'RUB';
    if (this.props.lang === 'uk') return 'UAH';
    return 'USD';
  }
  LangString = (key: langData, ...args: any[]) => {
    return langString(this.props.lang, key, ...args);
  };
  constructor(props: any) {
    super(props);
  }
}
