export const NODES_LIST: {
  id: string;
  name: langData;
  flag: string;
  protocols: ('vless' | 'ss' | 'http')[];
  type: 'marzban' | 'proxy';
}[] = [
  {
    id: 'aeza-stockholm',
    name: 'locationStockholm',
    flag: '🇸🇪',
    protocols: ['vless', 'ss'],
    type: 'marzban',
  },
  {
    id: 'aeza-stockholm-http',
    name: 'locationStockholm',
    flag: '🇸🇪',
    protocols: ['http'],
    type: 'proxy',
  },
];
