export const TARIF_LIST: {
  /** Уникальный ID тарифа */
  id: string;
  /** Название */
  name: langData;
  /** Приписка к названию учётки MarzBan. Требуется только для Marzban тарифов */
  marzbanName?: string;
  /** Количество дней */
  days: number;
  /** Стоимость */
  price: number;
  /** Старая стоимость (для отображения скидки) */
  oldPrice?: number;
  /** Лимит траффика в GB */
  gb: number;
  /** ID ноды из конфига */
  node: string;
  /** Выдать в качестве тестового доступа при создании аккаунта */
  trialHours?: number;
  /** Если указать true - то данный тариф клиент сам заказать не сможет. Он его сможет только продлить, если он у него уже есть, а также мы сможем выдать его вручную (ну и если у него есть параметр trialHours, так его клиент тоже сможет получить) */
  disable_order?: boolean;
}[] = [
  {
    id: 'example',
    name: 'exampleTarif',
    marzbanName: 'light',
    days: 30,
    price: 1.3,
    oldPrice: 5,
    gb: 20,
    node: 'aeza-stockholm',
    trialHours: 24,
  },
  {
    id: 'middle',
    name: 'middleTarif',
    marzbanName: 'middle',
    days: 30,
    price: 2.1,
    oldPrice: 20,
    gb: 55,
    node: 'aeza-stockholm',
  },
  {
    id: 'pro',
    name: 'proTarif',
    marzbanName: 'pro',
    days: 30,
    price: 3.5,
    oldPrice: 60,
    gb: 550,
    node: 'aeza-stockholm',
  },
  {
    id: 'http_pro',
    name: 'httpTarifPro',
    days: 30,
    price: 3.5,
    oldPrice: 60,
    gb: 550,
    node: 'aeza-stockholm-http',
  },
];
