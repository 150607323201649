import React from 'react';
import { BasePage } from './base';
import { langString } from '../../lang';
import { PROJECT_NAME, PROJECT_BOT } from '../../config/project';
import { navIcon } from '../modules/icon';
import { CurrencyConvertText } from '../../config/currency';
import { termText } from '../../config/terms';
import { CONTACTS_LIST } from '../../config/contacts';

export class ContactsPage extends BasePage<{}, {}> {
  state = {};

  constructor(props: any) {
    super(props);
  }

  render() {
    return (
      <>
        <section style={{ marginTop: '10px' }}>
          <div className="container contacts">
            {CONTACTS_LIST.map((q, i) => {
              return (
                <div key={i}>
                  <span className="title">{this.LangString(q.title)}</span>
                  {q.url ? <a href={q.url}>{q.text}</a> : <span>{q.text}</span>}
                </div>
              );
            })}
          </div>
        </section>
      </>
    );
  }
}
