export const termText: {
  [key: string]: {
    title: string;
    time: string;
    desc: string;
    sections: { title: string; items: string[] }[];
  };
} = {
  ru: {
    title: 'Пользовательское соглашение',
    time: 'Дата публикации: 6 декабря 2024 г.',
    desc: 'Данное пользовательское соглашение, размещенное в сети Интернет по сетевому адресу https://rocketman-vpn.com/?terms (далее – «Соглашение») регулирует отношения между физическими и юридическими лицами (далее – «Пользователи» или «Клиенты») и проектом rocketman-vpn.com (далее – «Проект» или «сайт»), размещенным в сети Интернет по сетевому адресу https://rocketman-vpn.com при использовании продуктов, разработанных и предоставляемых проектом (далее – «Продукты») или иных услуг, оказываемых Проектом.',
    sections: [
      {
        title: 'Информация о продуктах:',
        items: [
          'Продукты, разработанные проектом, включают в себя различные продукты, которые относятся к категории VPN, Proxy и другие продукты, предоставляемые на сайте rocketman-vpn.com.',
          'Указанный список продуктов не является полным и может отличаться от того, что представлен на сайте.',
          'Продукты могут содержать ссылки на сторонние ресурсы, которые не контролируются проектом. Проект не несет ответственности за содержание, точность и безопасность таких ресурсов.',
          'Продукты могут использоваться только в соответствии с их назначением и целями, указанными проектом.',
        ],
      },
      {
        title: 'Определения терминов',
        items: [
          'Пользователь (клиент) - физическое или юридическое лицо, использующее продукты проекта.',
          'Проект (сайт) - интернет-ресурс, доступный по адресу rocketman-vpn.com.',
          'Продукты (заказ) - услуга, предоставляемая проектом конечному потребителю.',
        ],
      },
      {
        title: 'Права и обязанности пользователей',
        items: [
          'Пользователи обязаны соблюдать правила использования Проекта и его продуктов.',
          'Пользователи несут полную ответственность за свои действия в рамках использования Проекта и его продуктов.',
        ],
      },
      {
        title: 'Пользователи не имеют права:',
        items: [
          'нарушать права интеллектуальной собственности, включая авторские права, товарные знаки и патенты, находящиеся в собственности Проекта или третьих лиц;',
          'вмешиваться в работу Проекта, включая взлом, изменение, уничтожение, нанесение вреда или создание иных препятствий для работы Проекта;',
          'использовать Проект и его продукты для нецелевых целей, в том числе для распространения вредоносного или незаконного контента;',
          'использовать Проект и его продукты для спама или иных форм нежелательной почтовой рассылки.',
        ],
      },
      {
        title: 'Пользователи имеют право на:',
        items: [
          'использование Проекта и его продуктов в соответствии с условиями Соглашения;',
          'получение технической или иной поддержки и консультации от Проекта.',
        ],
      },
      {
        title: 'Интеллектуальная собственность',
        items: [
          'Продукты, доступные в рамках Проекта, являются интеллектуальной собственностью Проекта или третьих лиц, предоставивших право на их использование.',
          'Пользователи не имеют права использовать Продукты, предоставленные в рамках Проекта, для иных целей, кроме как для личного использования.',
          'Пользователи не имеют права копировать, распространять, модифицировать, переводить, создавать производные работы, декомпилировать, разбирать или иным образом воспроизводить Продукты или их части без письменного разрешения Проекта.',
        ],
      },
      {
        title: 'Политика возврата средств',
        items: [
          'После оказания в надлежащем качестве Платной услуги (Заказ), деньги, потраченные на приобретение, возврату не подлежат',
          'В случае, если услуга не была оказана в полной мере или была оказана в ненаджежащем качестве (баланс личного кабинета не был пополнен либо сумма зачисления отличается от расчётной суммы при оплате заказа), Пользователь имеет право отказаться от услуги, обратившись в службу поддержки Проекта, и получить полную, либо частичную компенсацию её стоимости либо замену Заказа на аналогичный. Компенсация расчитывается на основании текущего статуса Заказа, и включает в себя такие параметры, как: Дата и время создания заказа, Дата и время окончания заказа, Стоимость заказа, Время работы заказа, Количество израсходованого траффика заказа.',
          'Проект обязуется рассматривать все заявки по возврату средств в срок до 15 рабочих дней',
        ],
      },
      {
        title: 'Ограничение ответственности',
        items: [
          'Проект не несет ответственности за ущерб, причиненный пользователям или третьим лицам в результате использования Проекта или его продуктов.',
          'Проект не несет ответственности за прерывание доступа к Проекту или его продуктам, возникшее по причинам, не зависящим от Проекта.',
          'Проект не несет ответственности за содержание и достоверность информации, предоставляемой пользователями или третьими лицами в рамках Проекта.',
          'Проект оставляет за собой право в любой момент отказать в обслуживании Клиенту без объяснения причин.',
          'Проект оставляет за собой право рассматривать обращения в срок более 15 рабочих в случае форс-мажорных обстоятельств, возникших по причинам, не зависящим от Проекта',
        ],
      },
      {
        title: 'Разрешение споров',
        items: [
          'Все споры и разногласия, возникающие в связи с использованием Проекта или его продуктов, решаются путем переговоров между пользователями и Проектом.',
          'В случае невозможности решения спора путем переговоров, спор подлежит разрешению в соответствии с международным законодательством.',
        ],
      },

      {
        title: 'Заключительные положения',
        items: [
          'Настоящее Соглашение является единственным документом, регулирующим отношения между пользователями и Проектом в рамках использования Проекта и его продуктов.',
          'Проект оставляет за собой право в любое время вносить изменения в Соглашение без предварительного уведомления пользователей.',
          'Продолжение использования Проекта после внесения изменений в Соглашение означает согласие пользователя с новыми условиями.',
        ],
      },
    ],
  },
  en: {
    title: 'User Agreement',
    time: 'Publication Date: December 6, 2024',
    desc: 'This User Agreement, posted on the Internet at the web address https://rocketman-vpn.com/?terms (hereinafter referred to as the "Agreement"), regulates the relationship between individuals and legal entities (hereinafter referred to as "Users" or "Clients") and the rocketman-vpn.com project (hereinafter referred to as "Project" or "Site"), located on the Internet at the web address https://rocketman-vpn.com, when using products developed and provided by the Project (hereinafter referred to as "Products") or other services provided by the Project.',
    sections: [
      {
        title: 'Information about Products:',
        items: [
          'The products developed by the project include various products that fall under the category of VPN, Proxy and other products provided at rocketman-vpn.com.',
          'The list of Products is not exhaustive and may differ from what is presented on the website.',
          'The Products may contain links to third-party resources that are not controlled by the Project. The Project is not responsible for the content, accuracy and security of such resources.',
          'The Products may only be used in accordance with their purpose and objectives as specified by the Project.',
        ],
      },
      {
        title: 'Definitions of Terms',
        items: [
          "User (client) - an individual or legal entity using the Project's Products.",
          'Project (Site) - an Internet resource available at the address rocketman-vpn.com.',
          'Products (Order) - a service provided by the project to the end user.',
        ],
      },
      {
        title: 'Rights and Obligations of Users',
        items: [
          'Users are required to comply with the rules for using the Project and its Products.',
          'Users are fully responsible for their actions in using the Project and its Products.',
        ],
      },
      {
        title: 'Users are not allowed to:',
        items: [
          'violate intellectual property rights, including copyrights, trademarks and patents, owned by the Project or third parties;',
          'interfere with the operation of the Project, including hacking, altering, destroying, damaging or creating other obstacles to the operation of the Project;',
          'use the Project and its Products for non-targeted purposes, including for the distribution of malicious or illegal content;',
          'use the Project and its Products for spam or other forms of unwanted email distribution.',
        ],
      },
      {
        title: 'Users have the right to:',
        items: [
          'use the Project and its Products in accordance with the terms of the Agreement;',
          'receive technical or other support and advice from the Project.',
        ],
      },
      {
        title: 'Intellectual Property',
        items: [
          'The Products available within the Project are the intellectual property of the Project or third parties who have granted the right to use them.',
          'Users are not authorized to use the Products provided within the Project for purposes other than personal use.',
          'Users are not authorized to copy, distribute, modify, translate, create derivative works, decompile, disassemble or otherwise reproduce the Products or their parts without the written permission of the Project.',
        ],
      },
      {
        title: 'Refund Policy',
        items: [
          'Once the Paid Service (Order) has been rendered in a proper quality, the money spent on the purchase is non-refundable',
          'In case the service was not rendered in full or was rendered in an unreliable quality (the balance of personal account was not replenished or the amount credited differs from the calculated amount when paying for the order), the User has the right to refuse the service by contacting the Project support service and receive full or partial compensation of its cost or replacement of the Order with a similar one. Compensation is calculated based on the current status of the Order, and includes such parameters as: Date and time of Order creation, Date and time of Order completion, Order cost, Order operation time, Amount of spent Order traffic.',
          'The project is committed to reviewing all refund requests within 15 business days',
        ],
      },
      {
        title: 'Limitation of Liability',
        items: [
          'The Project is not responsible for any damage caused to Users or third parties as a result of using the Project or its Products.',
          'The Project is not responsible for any interruption of access to the Project or its Products due to reasons beyond the control of the Project.',
          'The Project is not responsible for the content and accuracy of information provided by Users or third parties within the Project.',
          'The Project reserves the right to refuse to provide services to the Client at any time without explanation.',
          'The Project reserves the right to consider appeals within a period longer than 15 working days in case of force majeure circumstances arising for reasons beyond the control of the Project',
        ],
      },
      {
        title: 'Dispute Resolution',
        items: [
          'All disputes and disagreements arising in connection with the use of the Project or its Products are resolved through negotiations between Users and the Project.',
          'In the event that it is impossible to resolve a dispute through negotiations, the dispute shall be settled in accordance with international law.',
        ],
      },
      {
        title: 'Final Provisions',
        items: [
          'This Agreement is the only document regulating the relationship between Users and the Project in the use of the Project and its Products.',
          'The Project reserves the right to make changes to the Agreement at any time without prior notice to Users.',
          "Continued use of the Project after changes to the Agreement signifies the User's agreement with the new terms.",
        ],
      },
    ],
  },
  uk: {
    title: 'Користувацька угода',
    time: 'Дата публікації: 6 грудня 2024 р.',
    desc: 'Дана користувацька угода, розміщена в мережі Інтернет за мережевою адресою https://rocketman-vpn.com/?terms (далі – «Угода») регулює відносини між фізичними та юридичними особами (далі – «Користувачі» або «Клієнти») та проектом rocketman-vpn.com (далі – «Проект» або «сайт»), розміщеним в мережі Інтернет за мережевою адресою https://rocketman-vpn.com при використанні продуктів, розроблених та наданих проектом (далі – «Продукти») або інших послуг, наданих Проектом.',
    sections: [
      {
        title: 'Інформація про продукти:',
        items: [
          'Продукти, розроблені проектом, містять різні продукти, які належать до категорії VPN, Proxy та інші продукти, що надаються на сайті rocketman-vpn.com.',
          'Зазначений перелік продуктів не є повним та може відрізнятися від того, що представлено на сайті.',
          'Продукти можуть містити посилання на сторонні ресурси, які не контролюються проектом. Проект не несе відповідальності за зміст, точність та безпеку таких ресурсів.',
          'Продукти можуть використовуватися лише у відповідності до їх призначення та цілей, зазначених проектом.',
        ],
      },
      {
        title: 'Визначення термінів',
        items: [
          'Користувач (клієнт) - фізична або юридична особа, яка використовує продукти проекту.',
          'Проект (сайт) - інтернет-ресурс, доступний за адресою rocketman-vpn.com.',
          'Продукти (замовлення) - послуга, що надається проєктом кінцевому споживачеві.',
        ],
      },
      {
        title: "Права та обов'язки користувачів",
        items: [
          "Користувачі зобов'язані дотримуватися правил використання Проекту та його продуктів.",
          'Користувачі несуть повну відповідальність за свої дії в рамках використання Проекту та його продуктів.',
        ],
      },
      {
        title: 'Користувачі не мають права:',
        items: [
          'порушувати права інтелектуальної власності, включаючи авторські права, товарні знаки та патенти, які належать Проекту або третім особам;',
          'втручатися в роботу Проекту, включаючи взлом, зміну, знищення, заподіяння шкоди або створення інших перешкод для роботи Проекту;',
          'використовувати Проект та його продукти для нецільових цілей, зокрема для поширення шкідливого або незаконного контенту;',
          'використовувати Проект та його продукти для спаму або інших форм небажаної електронної пошти.',
        ],
      },
      {
        title: 'Користувачі мають право на:',
        items: [
          'використання Проекту та його продуктів відповідно до умов Угоди;',
          'отримання технічної або іншої підтримки та консультації від Проекту.',
        ],
      },
      {
        title: 'Інтелектуальна власність',
        items: [
          'Продукти, доступні в рамках Проекту, є інтелектуальною власністю Проекту або третіх осіб, які надали право на їх використання.',
          'Користувачі не мають права використовувати Продукти, надані в рамках Проекту, для інших цілей, крім як для особистого використання.',
          'Користувачі не мають права копіювати, поширювати, модифікувати, перекладати, створювати похідні роботи, декомпілювати, розбирати або будь-яким іншим способом відтворювати Продукти або їх частини без письмового дозволу Проекту.',
        ],
      },
      {
        title: 'Політика повернення коштів',
        items: [
          'Після надання в належній якості Платної послуги (Замовлення), гроші, витрачені на придбання, поверненню не підлягають',
          'У разі, якщо послуга не була надана в повній мірі або була надана в неналежній якості (баланс особистого кабінету не був поповнений або сума зарахування відрізняється від розрахункової суми під час оплати замовлення), Користувач має право відмовитися від послуги, звернувшись до служби підтримки Проєкту, та отримати повну або часткову компенсацію її вартості або заміну Замовлення на аналогічне. Компенсація розраховується на підставі поточного статусу Замовлення, і включає в себе такі параметри, як: Дата і час створення замовлення, Дата і час закінчення замовлення, Вартість замовлення, Час роботи замовлення, Кількість витраченого трафіку замовлення.',
          "Проєкт зобов'язується розглядати всі заявки по поверненню коштів в термін до 15 робочих днів",
        ],
      },
      {
        title: 'Обмеження відповідальності',
        items: [
          'Проект не несе відповідальності за шкоду, заподіяну користувачам або третім особам в результаті використання Проекту чи його продуктів.',
          'Проект не несе відповідальності за перерву в доступі до Проекту чи його продуктів, яка виникла з причин, незалежних від Проекту.',
          'Проект не несе відповідальності за зміст та достовірність інформації, наданої користувачами або третіми особами в рамках Проекту.',
          'Проект залишає за собою право в будь-який момент відмовити в обслуговуванні Клієнту без пояснення причин.',
          'Проєкт залишає за собою право розглядати звернення у строк понад 15 робочих у разі форс-мажорних обставин, що виникли з причин, не залежних від Проєкту',
        ],
      },
      {
        title: 'Вирішення спорів',
        items: [
          'Всі спори та розбіжності, що виникають у зв’язку з використанням Проекту чи його продуктів, вирішуються шляхом переговорів між користувачами та Проектом.',
          'У разі неможливості вирішення спору шляхом переговорів, спір підлягає вирішенню відповідно до міжнародного законодавства.',
        ],
      },
      {
        title: 'Заключні положення',
        items: [
          'Ця Угода є єдиним документом, що регулює відносини між користувачами та Проектом в рамках використання Проекту та його продуктів.',
          'Проект залишає за собою право в будь-який час вносити зміни до Угоди без попереднього повідомлення користувачів.',
          'Продовження використання Проекту після внесення змін до Угоди означає згоду користувача з новими умовами.',
        ],
      },
    ],
  },
};
