export const navIcon = (name: string) => {
  for (const format of ['svg', 'png']) {
    try {
      const itm = require(
        `../images/${name.replace('.' + format, '')}.${format}`
      )?.default;
      if (itm) return itm;
    } catch (error) {
      //
    }
  }
};
