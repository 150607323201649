import React from 'react';
import { BasePage } from './base';
import { langString } from '../../lang';
import { PROJECT_NAME, PROJECT_BOT } from '../../config/project';
import { navIcon } from '../modules/icon';
import { CurrencyConvertText } from '../../config/currency';
import { termText } from '../../config/terms';

const loadTerm = (lang: langType) => {
  const data = termText[lang] || termText['en'];

  return (
    <>
      <div className="title">{data.title}</div>
      <div className="time">{data.time}</div>
      <div className="desc">{data.desc}</div>
      {data.sections.map((section, i) => {
        return (
          <div className="section" key={i}>
            <div className="title">{section.title}</div>
            <div className="items">
              {section.items.map((item, i) => {
                return (
                  <div className="item" key={i}>
                    {item}
                  </div>
                );
              })}
            </div>
          </div>
        );
      })}
    </>
  );
};

export class TermPage extends BasePage<{}, {}> {
  state = {};

  constructor(props: any) {
    super(props);
  }

  render() {
    return (
      <>
        <section className="termsMain" style={{ marginTop: '10px' }}>
          <div className="container term">{loadTerm(this.props.lang)}</div>
        </section>
      </>
    );
  }
}
