import React from 'react';
import { BasePage } from './base';
import { langString } from '../../lang';
import { PROJECT_NAME, PROJECT_BOT } from '../../config/project';
import { TARIF_LIST } from '../../config/tarifs';
import { navIcon } from '../modules/icon';
import { CurrencyConvertText } from '../../config/currency';
import { TG_SUPPORT_USERNAME } from '../../config/admins';
import { NODES_LIST } from '../../config/nodes';

const FAQ_LIST: { title: langData; text: langData; arg?: string }[] = [
  {
    title: 'faq1title',
    text: 'faq1text',
  },
  {
    title: 'faq2title',
    text: 'faq2text',
    arg: PROJECT_BOT,
  },
  {
    title: 'faq3title',
    text: 'faq3text',
    arg: TG_SUPPORT_USERNAME,
  },
];

const FEATURES_LIST: { icon: string; title: langData; text: langData }[] = [
  {
    icon: 'anonymous',
    title: 'features1Title',
    text: 'features1Text',
  },
  {
    icon: 'speed',
    title: 'features2Title',
    text: 'features2Text',
  },
  {
    icon: 'easy',
    title: 'features3Title',
    text: 'features3Text',
  },
  {
    icon: 'support',
    title: 'features4Title',
    text: 'features4Text',
  },
];

export class LandingPage extends BasePage<{ openedFAQ?: string }, {}> {
  state: Readonly<{ openedFAQ?: string | undefined }> = {};

  constructor(props: any) {
    super(props);
    history.pushState({}, null as any, '/');
  }
  render() {
    return (
      <>
        <section id="about">
          <div className="container">
            <h2>{this.LangString('landingFeaturesTitle')}</h2>
            <div className="features">
              {FEATURES_LIST.map(feature => {
                return (
                  <div className="feature" key={feature.icon}>
                    <img
                      src={navIcon(feature.icon)}
                      alt={this.LangString(feature.title)}
                    />
                    <h3>{this.LangString(feature.title)}</h3>
                    <p>{this.LangString(feature.text)}</p>
                  </div>
                );
              })}
            </div>
          </div>
        </section>
        <section id="pricing">
          <div className="container">
            <h2>{this.LangString('landingTarifs')}</h2>
            <div className="plans">
              {TARIF_LIST.map(tarif => {
                if (tarif.disable_order) return <></>;
                const node = NODES_LIST.find(q => q.id === tarif.node);
                if (!node) return <></>;
                return (
                  <div className="plan" key={tarif.id}>
                    <h3>{this.LangString(tarif.name)}</h3>
                    <span className="type">
                      {node.type === 'proxy'
                        ? this.LangString('applicationCabinetPlatformProxy')
                        : node.protocols.join(', ').toUpperCase()}
                    </span>
                    {tarif.trialHours ? (
                      <span className="plan-badge">
                        {this.LangString(
                          'landingTarifsTrial',
                          tarif.trialHours
                        )}
                      </span>
                    ) : (
                      <></>
                    )}
                    {tarif.oldPrice ? (
                      <span className="plan-badge">
                        {this.LangString('landingTarifsDiscount')}{' '}
                        <b>
                          {((tarif.price / tarif.oldPrice) * 100).toFixed(0)}%
                        </b>
                      </span>
                    ) : (
                      <></>
                    )}
                    <p className="price">
                      {tarif.oldPrice ? (
                        <>
                          <s>
                            {CurrencyConvertText(
                              tarif.oldPrice,
                              this.currency,
                              true
                            )}
                          </s>{' '}
                        </>
                      ) : (
                        ''
                      )}
                      {this.LangString(
                        'landingTarifsPrice',
                        CurrencyConvertText(tarif.price, this.currency, true),
                        tarif.days
                      )}
                    </p>
                    <a
                      href={`https://t.me/${PROJECT_BOT}`}
                      target="_blank"
                      className="btn-primary"
                      rel="noreferrer"
                    >
                      {this.LangString('landingTarifsGo')}
                    </a>
                  </div>
                );
              })}
            </div>
          </div>
        </section>
        <section id="faq">
          <div className="container">
            <h2>FAQ</h2>
            {FAQ_LIST.map((q, i) => {
              const id = `faq_${i}`;
              return (
                <div className="faq-item" key={i}>
                  <h3
                    className={`faq-question ${this.state.openedFAQ === id ? 'active' : ''}`}
                    onClick={() => {
                      this.setState({
                        openedFAQ: this.state.openedFAQ === id ? '' : id,
                      });
                    }}
                  >
                    {this.LangString(q.title)}
                  </h3>
                  <div
                    className={`faq-answer ${this.state.openedFAQ === id ? 'show' : ''}`}
                  >
                    <p
                      dangerouslySetInnerHTML={{
                        __html: this.LangString(q.text, q.arg),
                      }}
                    />
                  </div>
                </div>
              );
            })}
          </div>
        </section>
      </>
    );
  }
}
